@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  background-color: white;
  color: black;
}

body {
  box-sizing: border-box;
}

input[type="range"]{
    accent-color: #0089D6;
    outline: none;
    width: 20px;
}

#range-vertical{

    -webkit-appearance: slider-vertical!important;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border-radius: 50%;
  background: #0089D6;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background .3s ease-in-out;
  margin-right: -5.4px;
}
input[type=range]::-webkit-slider-runnable-track  {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
  border: none;
}

/* Styles for horizontal range input */
.horizontal-range {
  width: 100%!important;
  margin-right: 0 !important;
  height: auto!important;
    -webkit-appearance: horizontal-range !important;
}

.horizontal-range::-webkit-slider-runnable-track {
  height: 5px;
  width: 100%;
}

.horizontal-range::-webkit-slider-thumb {
  margin-top: -5.4px !important;
}

/* Custom Table */

table { 
    border: 1px solid black;
border-collapse: separate !important;
    border-radius: 20px;
    border-spacing: 0px;
    width: 100%;
table-layout: auto;
}
.expanding-column {
  width: 100%; /* Set the expanding column to take all available space */
}

td {
    padding: 10px; 
    border-left: 1px solid black;    
    border-top: 1px solid black;    
}

tr:first-child td {
    border-top: none;
}

td:first-child {
    border-left: none;
}


/* custom scrollbar */
/* Ref: https://codepen.io/GhostRider/pen/oNvoNv */


#style-2::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: #F4F5F5;
}

#style-2::-webkit-scrollbar
{
	width: 8px;
	background-color: #F4F5F5;
}

#style-2::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: #0089D6;
}



.fb_iframe_widget iframe {
    height: 40px!important;
    opacity: 0;
}

/* Custom scrollbar styles for Webkit browsers */
::-webkit-scrollbar {
  width: 12px; /* Width of the entire scrollbar */
}

/* Hide the scrollbar arrows */
::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-track {
  background: #f5ebe8; /* Background of the scrollbar track */
  border-radius: 10px; /* Round corners of the track */
}

::-webkit-scrollbar-thumb {
  background-color: #4a4a4a; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Round corners of the thumb */
  border: 3px solid #f5ebe8; /* Space around the thumb */
}

/* Custom scrollbar styles for Firefox */
* {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #4a4a4a #f5ebe8; /* Thumb and track color */
}

/* Hide scrollbar for IE and Edge */
body {
  -ms-overflow-style: none; /* IE and Edge */
}

/* Additional style to ensure cross-browser compatibility */
body::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.html-image {
  margin: 0 auto;
}
